import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { AuthStateContext } from "~/base/auth";
import { getErrorResponseText, StatusIcon } from "~/blocks/upload-utils";
import { DetachedSelect } from "~/components/form/select";
import { axios } from "~/utils";
import { getVideoMetadata } from "~/utils/video";

function UploadAssetsModal({
  filesToUpload,
  onFileRemove,
  creative,
  onClose,
  languages,
}) {
  const [extraState, dispatch] = useReducer(
    extraStateReducer,
    filesToUpload,
    getInitialExtraState
  );
  useEffect(() => {
    filesToUpload.forEach((file) => {
      getVideoMetadata(file.file).then(({ duration, resolution }) => {
        dispatch({ type: "set-metadata", key: file.key, duration, resolution });
      });
    });
  }, [filesToUpload]);
  const [isRetryInProgress, setIsRetryInProgress] = useState(false);

  const uploadAsset = (file) => {
    dispatch({ type: "set-status", key: file.key, status: "uploading" });
    const state = extraState.find((e) => e.key === file.key);
    const formData = new FormData();
    formData.append("file", file.file);
    formData.append("file_type", file.file.type);
    formData.append("language", state.language);
    formData.append("duration", state.duration);
    formData.append("resolution", state.resolution);
    return axios({
      method: "POST",
      url: `creative-concepts/${creative.id}/upload_assets/`,
      data: formData,
      timeout: 5 * 60 * 1000,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    })
      .then(() => {
        dispatch({ type: "set-status", key: file.key, status: "success" });
      })
      .catch((error) => {
        processUploadError(error, creative.id);
        dispatch({
          type: "set-status",
          key: file.key,
          status: "error",
          error: getErrorResponseText(error),
        });
      });
  };
  const uploadAssets = () => {
    Promise.all(filesToUpload.map(uploadAsset));
  };
  const setLanguage = (key, language) => {
    dispatch({ type: "set-language", key, language });
  };
  const removeFile = (key) => {
    onFileRemove(key);
    dispatch({ type: "remove-entry", key });
  };
  const retryFailedUploads = () => {
    const failedFileKeys = extraState
      .filter((e) => e.status === "error")
      .map((e) => e.key);
    const failedFiles = filesToUpload.filter((e) =>
      failedFileKeys.includes(e.key)
    );
    setIsRetryInProgress(true);
    Promise.allSettled(failedFiles.map(uploadAsset)).finally(() => {
      setIsRetryInProgress(false);
    });
  };

  const [isDisabled, submitDisabledReason] = isSubmitDisabled(extraState);
  const isInitialState = extraState.every((e) => e.status === "prepare");
  const isUploadingState = extraState.some((e) => e.status === "uploading");
  const isTerminalState = extraState.every(
    (e) => e.status === "success" || e.status === "error"
  );
  const hasFailedUploads = extraState.some((e) => e.status === "error");
  const closeModal = () => {
    const shouldRefreshPage = !isInitialState;
    onClose(shouldRefreshPage);
  };
  const { user } = React.useContext(AuthStateContext);
  const { username } = user;

  return (
    <Modal backdrop="static" onHide={closeModal} show centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload new assets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUploadingState && (
          <div className="mb-2 ms-3">
            <p className="h6">
              Creatives are being uploaded to the Cactus server.
              <br />
              Please do not close the page until the uploads to Cactus are
              completed.
            </p>
          </div>
        )}
        {isTerminalState && (
          <div className="mb-2 ms-3">
            <p className="h6">
              Uploads to Cactus are completed.
              {hasFailedUploads && "You can retry the failed uploads."}
              <br />
              You can close the page or navigate to the{" "}
              <Link to={`/jobs/?user=${username}`}>Job Center</Link> for network
              uploads statuses.
            </p>
          </div>
        )}
        <Container>
          <Row className="mb-3">
            <Col xs={5} style={{ fontWeight: "bold" }}>
              File:
            </Col>
            <Col xs={1} style={{ fontWeight: "bold" }}>
              Duration:
            </Col>
            <Col xs={2} style={{ fontWeight: "bold" }}>
              Resolution:
            </Col>
            <Col xs={2} style={{ fontWeight: "bold" }}>
              Language:
            </Col>
            <Col xs={1} style={{ fontWeight: "bold" }}>
              {isInitialState ? "Remove:" : "Cactus Upload"}
            </Col>
          </Row>
          {filesToUpload.map((fileAdded) => (
            <UploadAssetBlock
              key={fileAdded.key}
              fileAdded={fileAdded}
              languages={languages}
              removeFile={removeFile}
              state={extraState.find((e) => e.key === fileAdded.key)}
              setLanguage={setLanguage}
              showRemoveColumn={isInitialState}
            />
          ))}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {!isTerminalState && (
          <ReasonTooltip reason={submitDisabledReason}>
            <Button
              variant="primary"
              onClick={uploadAssets}
              disabled={isDisabled}
            >
              Upload
            </Button>
          </ReasonTooltip>
        )}
        {isTerminalState && hasFailedUploads && (
          <Button
            variant="primary"
            onClick={retryFailedUploads}
            disabled={isRetryInProgress}
          >
            Retry all failed uploads
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function ReasonTooltip({ reason, children }) {
  if (reason) {
    return (
      <OverlayTrigger overlay={<Tooltip>{reason}</Tooltip>}>
        <span style={{ cursor: "pointer" }} className="d-inline-block">
          {children}
        </span>
      </OverlayTrigger>
    );
  }
  return <span className="d-inline-block">{children}</span>;
}

function UploadAssetBlock({
  fileAdded,
  languages,
  removeFile,
  state,
  setLanguage,
  showRemoveColumn,
}) {
  const defaultLanguage = languages.find((language) => language.label === "EN");
  useEffect(() => {
    setLanguage(state.key, defaultLanguage.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage.value]);
  return (
    <Row className="mb-3">
      <Col xs={5} className="break-word">
        {fileAdded.file.name}
      </Col>
      <Col xs={1} className="pt-1">
        <DelayedValue value={state.duration} />
      </Col>
      <Col xs={2} className="pt-1">
        <DelayedValue value={state.resolution} />
      </Col>
      <Col xs={2}>
        <DetachedSelect
          defaultValue={defaultLanguage}
          options={languages}
          onChange={(value) => setLanguage(state.key, value)}
        />
      </Col>
      {showRemoveColumn && (
        <Col xs={1}>
          <Button
            onClick={() => removeFile(fileAdded.key)}
            type="button"
            variant="outline-primary"
          >
            Remove
          </Button>
        </Col>
      )}
      <Col xs={1}>
        <StatusIcon status={state.status} error={state.error} />
      </Col>
    </Row>
  );
}

function DelayedValue({ value }) {
  if (value) {
    return <span>{value}</span>;
  }
  return (
    <FontAwesomeIcon
      className="fa fa-spin fa-2x"
      icon={faSpinner}
      color="#0d6efd"
    />
  );
}

function isSubmitDisabled(extraState) {
  if (extraState.some((e) => e.status === "uploading")) {
    return [true, "Upload is in progress."];
  }
  if (!extraState.every((e) => e.language)) {
    return [true, "Languages for all assets should be set before uploading."];
  }
  if (!extraState.every((e) => e.resolution && e.duration)) {
    return [
      true,
      "All resolutions and durations should be detected before uploading the assets.",
    ];
  }
  return [false, null];
}

function getInitialExtraState(filesToUpload) {
  return filesToUpload.map((file) => ({
    key: file.key,
    language: "",
    resolution: "",
    duration: null,
    status: "prepare",
    error: null,
  }));
}

function extraStateReducer(state, action) {
  const { type, key, ...payload } = action;
  if (type === "remove-entry") {
    return state.filter((e) => e.key !== key);
  }
  const transform = getTransformationFunction(type);
  return state.map((e) => (e.key === key ? transform(e, payload) : e));
}

function getTransformationFunction(type) {
  if (type === "set-metadata") {
    return (entry, payload) => {
      const { resolution, duration } = payload;
      return { ...entry, resolution, duration };
    };
  }
  if (type === "set-language") {
    return (entry, payload) => {
      const { language } = payload;
      return { ...entry, language };
    };
  }
  if (type === "set-status") {
    return (entry, payload) => ({
      ...entry,
      status: payload.status,
      error: payload.error || null,
    });
  }
  return (entry) => entry;
}

function processUploadError(error, creativeId) {
  if (error.response && error.response.status === 413) {
    axios.post("action-logs/", {
      custom_metadata: {
        messages: [{ text: "File size too large", type: "error" }],
      },
      creative_concept: creativeId,
      action_type: "creative_upload",
    });
  }
}

export { UploadAssetsModal };
