const defaultAlertTimeout = 5000;

/* eslint-disable no-underscore-dangle */
const environmentConfig =
  window && window._environmentConfig ? window._environmentConfig : {};
/* eslint-enable no-underscore-dangle */

function getEnvironmentName() {
  const shortName = environmentConfig.environmentShortName || "UNKN";
  const longName = environmentConfig.environmentName || "UNKNOWN";
  return [shortName, longName];
}

function getBaseURL() {
  const baseURL = environmentConfig.baseURL || "/crops-api";
  return baseURL;
}

const gdriveCredentials = {
  client_id: environmentConfig.gdriveClientId,
  redirect_uri: environmentConfig.gdriveRedirectUri,
  scope: "https://www.googleapis.com/auth/drive",
};

const youtubeCredentials = {
  client_id: environmentConfig.youtubeClientId,
  redirect_uri: environmentConfig.youtubeRedirectUri,
  scope: "https://www.googleapis.com/auth/youtube",
};

const googleAdsCredentials = {
  client_id: environmentConfig.googleAdsClientId,
  redirect_uri: environmentConfig.googleAdsRedirectUri,
  scope: " https://www.googleapis.com/auth/adwords",
};

export {
  getEnvironmentName,
  defaultAlertTimeout,
  getBaseURL,
  gdriveCredentials,
  youtubeCredentials,
  googleAdsCredentials,
};
