import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";

import {
  getErrorResponseText,
  StatusIcon,
  getDefaultOptionFromFilename,
  getDefaultCTA,
  getDefaultLanguage,
} from "~/blocks/upload-utils";
import { DetachedSelect } from "~/components/form/select";
import { axios } from "~/utils";

function UploadAssetsModal({
  filesToUpload,
  onFileRemove,
  playable,
  onClose,
  languages,
  networks,
}) {
  const [extraState, dispatch] = useReducer(
    extraStateReducer,
    filesToUpload,
    getInitialExtraState
  );
  const [isRetryInProgress, setIsRetryInProgress] = useState(false);

  function getFormData(file, state) {
    function addNotNull(data, key, value) {
      if (value !== null) {
        // eslint-disable-next-line  no-param-reassign
        data[key] = value;
      }
    }
    const { isQRCode } = state;
    const data = {
      file: file.file,
      file_type: file.file.type,
      language: state.language,
      is_qr_code: isQRCode,
    };
    if (!isQRCode) {
      // multipart/form-data does not support null values, so we remove them here
      // and use the default values on the backend
      addNotNull(data, "network", state.network);
      addNotNull(data, "clicks", state.clicks);
      addNotNull(data, "cta", state.cta);
    }
    addNotNull(data, "change", state.change);
    return data;
  }

  const uploadAsset = (file) => {
    dispatch({ type: "set-status", key: file.key, status: "uploading" });
    const state = extraState.find((e) => e.key === file.key);
    const formData = new FormData();
    const data = getFormData(file, state);
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return axios({
      method: "POST",
      url: `playable-concepts/${playable.id}/upload_assets/`,
      data: formData,
      timeout: 1 * 60 * 1000,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    })
      .then(() => {
        dispatch({ type: "set-status", key: file.key, status: "success" });
      })
      .catch((error) => {
        processUploadError(error, playable.id);
        dispatch({
          type: "set-status",
          key: file.key,
          status: "error",
          error: getErrorResponseText(error),
        });
      });
  };
  const uploadAssets = () => {
    Promise.all(filesToUpload.map(uploadAsset));
  };
  const setLanguage = (key, language) => {
    dispatch({ type: "set-language", key, language });
  };
  const setNetwork = (key, network) => {
    dispatch({ type: "set-network", key, network });
  };
  const setCTA = (key, cta) => {
    dispatch({ type: "set-cta", key, cta });
  };
  const removeFile = (key) => {
    onFileRemove(key);
    dispatch({ type: "remove-entry", key });
  };
  const setNumberOfClicks = (key, clicks) => {
    dispatch({ type: "set-clicks", key, clicks });
  };
  const setChange = (key, change) => {
    dispatch({ type: "set-change", key, change });
  };
  const setIsQRCode = (key, isQRCode) => {
    dispatch({ type: "set-qr-code", key, isQRCode });
  };
  const retryFailedUploads = () => {
    const failedFileKeys = extraState
      .filter((e) => e.status === "error")
      .map((e) => e.key);
    const failedFiles = filesToUpload.filter((e) =>
      failedFileKeys.includes(e.key)
    );
    setIsRetryInProgress(true);
    Promise.allSettled(failedFiles.map(uploadAsset)).finally(() => {
      setIsRetryInProgress(false);
    });
  };

  const [isDisabled, submitDisabledReason] = isSubmitDisabled(extraState);
  const isInitialState = extraState.every((e) => e.status === "prepare");
  const isUploadingState = extraState.some((e) => e.status === "uploading");
  const isTerminalState = extraState.every(
    (e) => e.status === "success" || e.status === "error"
  );
  const hasFailedUploads = extraState.some((e) => e.status === "error");
  const closeModal = () => {
    const shouldRefreshPage = !isInitialState;
    onClose(shouldRefreshPage);
  };

  return (
    <Modal backdrop="static" onHide={closeModal} show centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload new assets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUploadingState && (
          <div className="mb-2 ms-3">
            <p className="h6">
              Playables are being uploaded to the Cactus server.
              <br />
              Please do not close the page until the uploads to Cactus are
              completed.
            </p>
          </div>
        )}
        {isTerminalState && (
          <div className="mb-2 ms-3">
            <p className="h6">
              Uploads to Cactus are completed.
              {hasFailedUploads && "You can retry the failed uploads."}
              <br />
            </p>
          </div>
        )}
        <div className="playable-upload-modal mx-2">
          <Row className="mb-3">
            <Col className="header width-x4">File:</Col>
            <Col className="header width-x2">Change:</Col>
            <Col className="header">Number of clicks:</Col>
            <Col className="header width-x1p5">Network:</Col>
            <Col className="header">CTA:</Col>
            <Col className="header">Language:</Col>
            <Col className="header">QR code:</Col>
            <Col className="header" />
            {isInitialState && <Col className="header" />}
          </Row>
          {filesToUpload.map((fileAdded) => (
            <UploadAssetBlock
              key={fileAdded.key}
              fileAdded={fileAdded}
              languages={languages}
              networks={networks}
              removeFile={removeFile}
              state={extraState.find((e) => e.key === fileAdded.key)}
              setLanguage={setLanguage}
              setNetwork={setNetwork}
              setCTA={setCTA}
              setNumberOfClicks={setNumberOfClicks}
              setChange={setChange}
              setIsQRCode={setIsQRCode}
              showRemoveColumn={isInitialState}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!isTerminalState && (
          <ReasonTooltip reason={submitDisabledReason}>
            <Button
              variant="primary"
              onClick={uploadAssets}
              disabled={isDisabled}
            >
              Upload
            </Button>
          </ReasonTooltip>
        )}
        {isTerminalState && hasFailedUploads && (
          <Button
            variant="primary"
            onClick={retryFailedUploads}
            disabled={isRetryInProgress}
          >
            Retry all failed uploads
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function ReasonTooltip({ reason, children }) {
  if (reason) {
    return (
      <OverlayTrigger overlay={<Tooltip>{reason}</Tooltip>}>
        <span style={{ cursor: "pointer" }} className="d-inline-block">
          {children}
        </span>
      </OverlayTrigger>
    );
  }
  return <span className="d-inline-block">{children}</span>;
}

function generateNumberOfClicksOptions(num) {
  const clicksOptions = [{ label: "DEF", value: null }];
  for (let i = 1; i <= num; i++) {
    clicksOptions.push({ label: i.toString(), value: i });
  }
  return clicksOptions;
}

function UploadAssetBlock({
  fileAdded,
  languages,
  networks,
  removeFile,
  state,
  setLanguage,
  setNetwork,
  setCTA,
  setNumberOfClicks,
  setChange,
  setIsQRCode,
  showRemoveColumn,
}) {
  const ctaOptions = [
    { value: true, label: "CTA" },
    { value: false, label: "NCTA" },
  ];
  const clicksOptions = generateNumberOfClicksOptions(50);
  const defaultClicks = clicksOptions.find((click) => click.label === "DEF");
  const filename = fileAdded.file.name;
  const defaultLanguage = getDefaultLanguage(filename, languages);
  const defaultNetwork = getDefaultOptionFromFilename(filename, networks);
  const defaultCTA = getDefaultCTA(filename, ctaOptions, defaultNetwork);
  useEffect(() => {
    setLanguage(state.key, defaultLanguage ? defaultLanguage.value : null);
    setNetwork(state.key, defaultNetwork ? defaultNetwork.value : null);
    setCTA(state.key, defaultCTA ? defaultCTA.value : null);
    setNumberOfClicks(state.key, defaultClicks.value);
    setChange(state.key, null);
    setIsQRCode(state.key, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage.value]);
  return (
    <Row className="mb-3">
      <Col className="width-x4 break-word">{fileAdded.file.name}</Col>
      <Col className="width-x2">
        <Form.Control
          defaultValue={null}
          type="text"
          onChange={(e) => setChange(state.key, e.target.value)}
        />
      </Col>
      <Col>
        <DetachedSelect
          defaultValue={defaultClicks}
          options={clicksOptions}
          onChange={(value) => setNumberOfClicks(state.key, value)}
          isDisabled={state.isQRCode}
        />
      </Col>
      <Col className="width-x1p5">
        <DetachedSelect
          defaultValue={defaultNetwork}
          options={networks}
          onChange={(value) => setNetwork(state.key, value)}
          isError={state.network === null}
          isDisabled={state.isQRCode}
        />
      </Col>
      <Col>
        <DetachedSelect
          defaultValue={defaultCTA}
          options={ctaOptions}
          onChange={(value) => setCTA(state.key, value)}
          isError={state.cta === null}
          isDisabled={state.isQRCode}
        />
      </Col>
      <Col>
        <DetachedSelect
          defaultValue={defaultLanguage}
          options={languages}
          onChange={(value) => setLanguage(state.key, value)}
          isError={state.language === null}
        />
      </Col>
      <Col>
        <Form.Check
          className="qr-code-checkbox mx-3 my-1"
          onChange={(e) => setIsQRCode(state.key, e.target.checked)}
        />
      </Col>
      {showRemoveColumn && (
        <Col>
          <Button
            onClick={() => removeFile(fileAdded.key)}
            type="button"
            variant="outline-primary"
          >
            Remove
          </Button>
        </Col>
      )}
      <Col>
        <StatusIcon status={state.status} error={state.error} />
      </Col>
    </Row>
  );
}

function isSubmitDisabled(extraState) {
  const notNull = (val) => val !== null;
  if (extraState.some((e) => e.status === "uploading")) {
    return [true, "Upload is in progress."];
  }
  if (
    !extraState.every((e) => {
      const requiredFields = e.isQRCode
        ? ["language"]
        : ["language", "network", "cta"];
      return requiredFields.every((f) => notNull(e[f]));
    })
  ) {
    return [true, "All required asset fields should be set before uploading."];
  }
  return [false, null];
}

function getInitialExtraState(filesToUpload) {
  return filesToUpload.map((file) => ({
    key: file.key,
    duration: null,
    status: "prepare",
    error: null,
  }));
}

function extraStateReducer(state, action) {
  const { type, key, ...payload } = action;
  if (type === "remove-entry") {
    return state.filter((e) => e.key !== key);
  }
  const transform = getTransformationFunction(type);
  return state.map((e) => (e.key === key ? transform(e, payload) : e));
}

function getTransformationFunction(type) {
  if (type === "set-language") {
    return (entry, payload) => {
      const { language } = payload;
      return { ...entry, language };
    };
  }
  if (type === "set-network") {
    return (entry, payload) => {
      const { network } = payload;
      return { ...entry, network };
    };
  }
  if (type === "set-cta") {
    return (entry, payload) => {
      const { cta } = payload;
      return { ...entry, cta };
    };
  }
  if (type === "set-status") {
    return (entry, payload) => ({
      ...entry,
      status: payload.status,
      error: payload.error || null,
    });
  }
  if (type === "set-clicks") {
    return (entry, payload) => {
      const { clicks } = payload;
      return { ...entry, clicks };
    };
  }
  if (type === "set-change") {
    return (entry, payload) => {
      const { change } = payload;
      return { ...entry, change };
    };
  }
  if (type === "set-qr-code") {
    return (entry, payload) => {
      const { isQRCode } = payload;
      return { ...entry, isQRCode };
    };
  }
  return (entry) => entry;
}

function processUploadError(error, creativeId) {
  if (error.response && error.response.status === 413) {
    axios.post("action-logs/", {
      custom_metadata: {
        messages: [{ text: "File size too large", type: "error" }],
      },
      creative_concept: creativeId,
      action_type: "playable_upload",
    });
  }
}

export { UploadAssetsModal };
